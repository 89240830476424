<script setup lang="ts">
import { defineComponent, h } from 'vue'

const navigation = {
    main: [
        { name: 'Company', href: '/about' },
        // { name: 'Blog', href: '/about/blog' },
        // { name: 'Solutions', href: '/solutions' },
        { name: 'Contact', href: '/contact' },
        { name: 'Privacy', href: '/privacy' },
    ],
    social: [
        {
            name: 'X',
            href: 'https://twitter.com/CiviqaApp',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
                        }),
                    ]),
            }),
        },

        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/civiqa',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            'd': 'M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}
</script>

<template>
    <footer>
        <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
            <nav
                class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
                aria-label="Footer"
            >
                <div
                    v-for="item in navigation.main"
                    :key="item.name"
                    class="pb-6"
                >
                    <NuxtLink
                        :href="item.href"
                        class="text-sm leading-6 text-gray-600 hover:text-gray-900"
                    >
                        {{ item.name }}
                    </NuxtLink>
                </div>
            </nav>

            <div class="mt-10 flex justify-center space-x-10">
                <NuxtLink
                    v-for="item in navigation.social"
                    :key="item.name"
                    :to="item.href"
                    target="_blank"
                    class="text-gray-400 hover:text-gray-500"
                >
                    <span class="sr-only">{{ item.name }}</span>

                    <component
                        :is="item.icon"
                        class="size-6"
                        aria-hidden="true"
                    />
                </NuxtLink>
            </div>

            <p class="mt-10 text-center text-xs leading-5 text-gray-900">
                &copy; {{ new Date().getFullYear() }}
                Civiqa Inc. All rights reserved.
            </p>
        </div>
    </footer>
</template>
